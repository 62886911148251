<template>
  <div>
    <br />
    <h4 style="margin-left: 2%"><b>Counselor Chat With Student</b></h4>
    <br />
    <b-card class="chat-widget" no-body>
      <b-card-header>
        <div class="d-flex align-items-center">
          <b-avatar
            size="34"
            :src="FILESURL + counselor.user_profile_image"
            class="mr-50 badge-minimal"
            badge-variant="success"
          />
          <h5 class="mb-0">
            {{ counselor.user_firstname + " " + counselor.user_lastname }}
          </h5>
        </div>
      </b-card-header>

      <section class="chat-app-window">
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log :chat="chat" :counselor="counselor" :student="student" />
        </vue-perfect-scrollbar>
      </section>
    </b-card>
    <b-card class="chat-widget" no-body>
      <b-card-header>
        <div class="d-flex align-items-center">
          <b-avatar
            size="34"
            :src="FILESURL + application.user_profile_image"
            class="mr-50 badge-minimal"
            badge-variant="success"
          />
          <h5 class="mb-0">
            {{ application.user_firstname + " " + application.user_lastname }}
          </h5>
        </div>
      </b-card-header>

      <section class="chat-app-window">
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log :chat="app_chats" :counselor="application" :student="student" />
        </vue-perfect-scrollbar>
      </section>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatLog from "./ChatLog.vue";
import OperationsServices from "@/apiServices/OperationsServices";
import { FILESURL } from "@/config";
import AgentServices from "@/apiServices/AgentServices";

export default {
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    ChatLog,
    VuePerfectScrollbar,
  },
  data() {
    return {
      student_user_id: null,
      counselor: {},
      application: {},
      app_chats:[],
      student: {},
      chat: [],
      FILESURL,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.psToBottom();
  },
  methods: {
    async getStudentCounselorChat(student_user_id) {
      try {
        const response = await AgentServices.getStudentCounselorChat({
          student_user_id,
        });
        if (response.data.status) {
          this.counselor = response.data.data.counselor;
          this.application = response.data.data.application_details.application;
          this.app_chats = response.data.data.application_details.app_messages;
          this.student = response.data.data.student;
          this.chat = response.data.data.chat;
        }
      } catch (error) {
        console.log("Error in getting Student Profile ", error);
      }
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.student_user_id = student_user_id;
      this.getStudentCounselorChat(student_user_id);
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
